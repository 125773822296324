
.p-6 {
    padding: 4.5rem !important;
}

.m-6 {
    margin: 4.5rem !important;
}

.mt-6 {
    margin-top: 4.5rem !important;
}

.mb-6 {
    margin-bottom: 4.5rem !important;
}

.fs-7 {
    font-size: .875rem !important;
}

.fs-8 {
    font-size: .75rem !important;
}

body {
    font-family: Rubik, sans-serif;
    font-size: 1rem;
    font-weight: 400;
}

.page-title, .page-title-overlap, .under-nav {
    margin-top: 80px;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: #228c36;
    font-family: Rubik, sans-serif;
    font-size: 1rem;
    font-weight: 400;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-bottom: .875rem;
        padding-top: 1.125rem;
    }
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: #228c36;
}

.bg-darker {
    background-color: #2b3445 !important;
}

.box-shadow-sm {
    box-shadow: 0 .125rem .3rem -.0625rem #00000008, 0 .275rem .75rem -.0625rem #0000000f !important;
}

.feature img {
    border-left: 0.5rem solid black;
    border-right: 0.5rem solid black;
    border-radius: 0.5rem;
}

.feature .feature-item {
    width: 100%;
}

@media (min-width: 768px) {
    .feature .feature-item {
        width: 30%;
    }

    .banner {
        width: 80%;
    }
}

@media (min-width: 992px) {
    .banner {
        width: 60%;
    }
}


.nutr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 670px;
    gap: 3rem;
    position: relative;
}

@media (max-width: 991px) {
    .nutr {
        gap: 1rem;
        height: 1970px;
        flex-wrap: nowrap;
    }

    .page-title, .page-title-overlap, .under-nav {
        margin-top: 72px;
    }
}

.search-component {
    position: relative;
    padding-right: 1.5rem;
    width: 100%;
}

.search-component-results {
    position: absolute;
    z-index: 1000;
    top: 45px;
    left: 0px;
    display: block;
}

.was-validated .password-toggle .form-control:invalid + .password-toggle-btn {
    top: 24px;
}

.czi-eye-off {
    color: #fe696a;
}

.czi-eye-off::before {
    content: "\e937";
}

.modal-open .navbar-toggler {
    margin-right: 0 !important;
}

.navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.navbar-brand span {
    font-size: 2rem;
}

input[type=checkbox].line-through-checked:checked + label {
    text-decoration: line-through;
}

.mobile-footer {
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 2000;
    margin-bottom: -3px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.mobile-footer a {
    display: flex;
    flex-direction: column;
    font-size: 0.7rem;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    text-decoration: none;
}

.mobile-footer a i {
    font-size: 1.3rem;
}

@media (max-width: 575.98px) {
    .btn-block-xs {
        display: block;
        width: 100%;
    }

    ol.list-style {
        padding-inline-start: 2rem;
    }

    .product-card .product-floating-btn {
        opacity: 1;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .btn-block-sm {
        display: block;
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .btn-block-md {
        display: block;
        width: 100%;
    }
}

.dot {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    display: inline-block;
}

.dot i {
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 3rem;
}

.media-tabs {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    border: 0;
    margin-bottom: 1.25rem;
}

.media-body {
    flex: 1;
}

.active .media-tab-media {
    background-color: #228c36;
    border-color: #228c36;
    box-shadow: 0 .5rem 1.125rem -.5rem #228c36e6;
    color: #fff;
}

.media-tab-media {
    background-color: #fff;
    border: 1px solid #e3e9ef;
    border-radius: 50%;
    color: #4b566b;
    height: 3.75rem;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: color .25s ease-in-out, background-color .25s ease-in-out, box-shadow .25s ease-in-out, border-color .25s ease-in-out;
    width: 3.75rem;
}

.nav-link-style {
    color: #4b566b !important;
    text-decoration: none !important;
}


.page-title-overlap {
    padding-bottom: 6.375rem;
}

.page-title-overlap + * {
    margin-top: -4.875rem;
    position: relative;
    z-index: 5;
}

.box-shadow-lg {
    box-shadow: 0 .3rem 1.525rem -.375rem #0000001a !important;
}

@media (max-width: 991px) {
    .page-title-overlap-lg {
        padding-bottom: 1.5rem;
        margin-bottom: 1rem;
        margin-top: 68px;
    }
}

@media (min-width: 992px) {
    .page-title-overlap-lg {
        padding-bottom: 6.375rem;
        margin-top: 76px;
    }

    .page-title-overlap-lg + * {
        position: relative;
        margin-top: -4.875rem;
        z-index: 5;
    }
}

@media (min-width: 992px) {
    .product-details {
        max-width: 26rem;
        padding-top: .25rem;
        width: 100%;
    }
}

ol.list-style {
    padding-inline-start: 2.5rem;
}

ol.list-style li:marker {
    content: "." counter(li);
    direction: rtl;
    font-weight: inherit;
    margin-left: -1.5em;
    margin-right: .5em;
    text-align: right;
    white-space: nowrap;
}

.list-style li::marker {
    color: #96255e;
    display: inline-block;
    font-weight: 500;
    margin-left: -1em;
    width: 1em;
}

.text-bg-accent {
    background-color: #96255e;
}

.text-accent {
    color: #96255e;
}
